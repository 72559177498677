export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '90e6448d-b1d8-47cc-b9b3-95f1af236210',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: 'd84c2e1e-uaxv-6314-usbz-886320696a82',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.services4eo.com/'
};
